<template>
   <v-main>
      <v-container>
         <v-row>
            <v-col>
            <v-card-title>
            {{ pageTitle }}
            </v-card-title>
            </v-col>
         </v-row>
         <v-row>
            <v-col>
               <v-data-table 
                  :headers="tableHeaders" 
                  :items="pendingAssignments" 
                  :server-items-length="totalAssignments"
                  :options.sync="options"
                  :loading="isLoading" 
                  class="elevation-1"
                  :footer-props="{
                          showFirstLastPage: true,
                          'items-per-page-options': rowsPerPage}"
                  >
                  <template #item.full_name="{ item }">
                     {{ item.patientFirstName }} {{ item.patientLastName }}
                  </template>
               </v-data-table>
            </v-col>
         </v-row>
      </v-container>
   </v-main>
</template>
<script>
import { PodAssignmentService } from "@/services/PodAssignmentService";

export default {
   data: () => ({
      pageTitle: 'Pod Assignment Pending',
      isLoading: null,
      options: {
         page: 1
      },
      pendingAssignments: [],
      totalAssignments: 0,
      tableHeaders: [
         {
            text: "Customer Name",
            align: "start",
            value: "customerName",
            sortable: true
         },
         {
            text: "Patient Name",
            value: "full_name",
            sortable: true
         },
         {
            text: "Patient Designation",
            value: "patientDesignation",
            sortable: true
         },
      ],
   }),
   computed: {
      rowsPerPage() {
         return this.$rowsPerPage;
      }
   },
   watch: {
      options: {
         handler() {
            this.getAssignments();
         },
         deep: true
      },
   },
   methods: {
      getAssignments() {
         this.isLoading = true;
         let pagedOptions = {
            ItemsPerPage: this.options.itemsPerPage,
            Page: this.options.page
         };

         new PodAssignmentService().get(pagedOptions)
            .then((data) => {
               this.pendingAssignments = data.items;
               this.totalAssignments = data.totalCount;
               this.isLoading = false;
            });
      },
   },
};
</script>
